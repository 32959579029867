import useApi from '@/hooks/useApi'

export const handleRitProp = (rit, newValue, propertyApi, propertyRit, data = {}) => {
  const api = useApi()

  return api('RIT_PROP_VALUE', {
    id: rit.id,
    property: propertyApi,
    value: newValue,
    ...data,
  }).then(() => {
    rit[propertyRit || propertyApi] = newValue
  })
}
