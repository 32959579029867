<template>
  <div>
    <h2 class="mb-2">Werkdruk</h2>
    <UITableResponsive :items="items" :headers="['Chauffeur', 'Werkdruk', 'Tijd_Werkdruk', 'Kilometer_Werkdruk', 'Ritten_Werkdruk', '']">
      <template v-slot:colgroup>
        <colgroup>
          <col class="w-8" />
          <col class="w-12" />
          <col class="w-12" />
          <col class="w-12" />
          <col class="w-12" />
          <col class="w-full" />
        </colgroup>
      </template>
      <template v-slot:item-Chauffeur="{ item }">
        <span class="pr-8">{{ store.getters.chauffeur(item.chauffeur_id)?.label }}</span>
      </template>
      <template v-slot:item-Werkdruk="{ item }">
        <div class="flex justify-between mx-auto text-center px-2 rounded-full font-bold" :class="labelColorClass(item.percentage_werkdruk)">
          <span>{{ item.percentage_werkdruk }} %</span>
        </div>
      </template>
      <template v-slot:item-Tijd_Werkdruk="{ item }">
        <div class="flex justify-between mx-auto text-center px-2 rounded-full font-bold" :class="labelColorClass(item.percentage_seconden)">
          <span v-if="item.seconden > 0">{{ hms(item.seconden) }}</span>
          <span v-if="item.seconden > 0">&mdash;</span>
          <span>{{ item.percentage_seconden }} %</span>
        </div>
      </template>
      <template v-slot:item-Kilometer_Werkdruk="{ item }">
        <div class="flex justify-between mx-auto text-center px-2 rounded-full font-bold" :class="labelColorClass(item.percentage_kilometers)">
          <span v-if="item.kilometers > 0">{{ item.kilometers }}</span>
          <span v-if="item.kilometers > 0">&mdash;</span>
          <span>{{ item.percentage_kilometers }} %</span>
        </div>
      </template>
      <template v-slot:item-Ritten_Werkdruk="{ item }">
        <div class="flex justify-between mx-auto text-center px-2 rounded-full font-bold" :class="labelColorClass(item.percentage_aantal)">
          <span v-if="item.aantal > 0">{{ item.aantal }}</span>
          <span v-if="item.aantal > 0">&mdash;</span>
          <span>{{ item.percentage_aantal }} %</span>
        </div>
      </template>
    </UITableResponsive>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import UITableResponsive from '@/components/UI/Table/Responsive'
import { useStore } from 'vuex'

const props = defineProps({
  kalender: Array,
})

const store = useStore()

const MAXIMUMS = {
  kilometers: 300,
  seconden: 60 * 60 * 4,
  aantal: 4,
}

const items = computed(() => {
  const result = {}

  store.state.chauffeurs.forEach((chauffeur) => {
    if (!chauffeur.is_active || chauffeur.type !== 'chauffeur') return

    result[chauffeur.value] = {
      chauffeur_id: chauffeur.value,
      kilometers: 0,
      seconden: 0,
      aantal: 0,
      is_active: chauffeur.is_active,
    }
  })

  props.kalender.forEach((item) => {
    item.chauffeurs?.forEach((chauffeur) => {
      const chauffeurId = chauffeur.chauffeur_id

      result[chauffeurId] = result[chauffeurId] || {
        chauffeur_id: chauffeurId,
        kilometers: 0,
        seconden: 0,
        aantal: 0,
      }

      result[chauffeurId].kilometers += Math.max(item.rit.kilometers, 0)
      result[chauffeurId].seconden += Math.max(item.rit.seconden, 0)
      result[chauffeurId].aantal += 1
    })
  })

  return Object.values(result).map((el) => {
      el.kilometers = Math.round(Math.max(el.kilometers, 0))
      el.seconden = Math.round(Math.max(el.seconden, 0))
      el.aantal = Math.round(Math.max(el.aantal, 0))

      el.percentage_kilometers = el.kilometers <= 0 ? 0 : parseFloat((el.kilometers / MAXIMUMS.kilometers * 100).toFixed(2))
      el.percentage_seconden = el.seconden <= 0 ? 0 : parseFloat((el.seconden / MAXIMUMS.seconden * 100).toFixed(2))
      el.percentage_aantal = el.aantal <= 0 ? 0 : parseFloat((el.aantal / MAXIMUMS.aantal * 100).toFixed(2))

      el.percentage_werkdruk = parseFloat(((el.percentage_kilometers + el.percentage_seconden + el.percentage_aantal) / 3).toFixed(2))

      return el
  }).filter(el => el.is_active || el.aantal > 0)
})

const labelColorClass = (percentage) => {
  if (percentage <= 50) return 'bg-green-500 text-green-100'
  if (percentage <= 75) return 'bg-yellow-300 text-yellow-900'
  return 'bg-red-500 text-red-900'
}

const hms = (seconden = 0) => {
  var sec_num = parseInt(seconden, 10) // don't forget the second param
  var hours   = Math.floor(sec_num / 3600)
  var minutes = Math.floor((sec_num - (hours * 3600)) / 60)
  var seconds = sec_num - (hours * 3600) - (minutes * 60)

  if (hours   < 10) {hours   = '0'+hours}
  if (minutes < 10) {minutes = '0'+minutes}
  if (seconds < 10) {seconds = '0'+seconds}
  return hours+':'+minutes+':'+seconds
}
</script>
