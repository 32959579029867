import { computed, ref, unref, watch } from 'vue'
import isRitRetourDeel from './isRitRetourDeel'
import useApi from '@/hooks/useApi'

export default (kalenderList = []) => {
  const api = useApi()

  const combinatiesKiezen = ref(false)
  const combinatieKeuze = ref({})

  const combinatieIndexes = computed(() => Object.entries(combinatieKeuze.value).reduce((result, [key, value]) => {
    if (value) result.push(Number(key))
    return result
  }, []))

  watch(combinatiesKiezen, (val, oldval) => {
    if (val === oldval) return
    combinatieKeuze.value = {}
  })

  const handleCombineren = async () => {
    if (handleCombineren.loading) return

    const list = unref(kalenderList)
    const newCombinatieId = Math.max(...list.map(record => Math.max(record?.rit?.combinatie_status || 0))) + 1
    const records = list.filter((_, index) => combinatieIndexes.value.includes(index)).map((record) => ({
      rit_id: record.rit?.id,
      is_retour: isRitRetourDeel(record?.rit),
    }))

    if (records.length < 2) return

    handleCombineren.loading = true
    await api('KALENDER_COMBINEREN', { id: newCombinatieId, records }).then(() => {
      list.forEach((record, index) => {
        if (combinatieIndexes.value.includes(index)) {
          record.rit.combinatie_status = newCombinatieId
        }
      })
      combinatiesKiezen.value = false
    })
    handleCombineren.loading = false
  }

  return {
    combinatiesKiezen,
    combinatieKeuze,
    combinatieIndexes,
    handleCombineren,
  }
}
