<template>
  <button
    class="btn shadow-none text-xs font-bold rounded-sm"
    :class="{
      'bg-gray-200 text-gray-500': !value && !facturatie_id,
      'bg-yellow-300 text-yellow-900': value && !facturatie_id,
      'bg-green-400 text-yellow-900': facturatie_id,
    }"
  >
    <i class="fas" :class="{ 'fa-times': !value && !facturatie_id,  'fa-check': value || facturatie_id }"></i>
    <i v-if="facturatie_id" class="fas fa-check"></i>
    Factuur
    <small v-if="journal?.label || factuur_nummer" v-show="value || facturatie_id">
      <b>[{{ [String(journal.label || '').replace('Facturatie', '').trim(), factuur_nummer].filter(el => !!el).join(' - ') }}]</b>
    </small>
  </button>
</template>

<script setup>
import { FINANCIAL_JOURNALS } from '@/constants/OPTIONS'
import { computed, toRefs } from 'vue'

const props = defineProps({
  value: Boolean,
  facturatie_id: Number,
  journal_id: Number,
  factuur_nummer: String,
})

const { journal_id } = toRefs(props)

const journal = computed(() => FINANCIAL_JOURNALS.find(j => j.value == journal_id.value))
</script>
